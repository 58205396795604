import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { Helmet } from 'react-helmet';
import { collection, addDoc } from 'firebase/firestore';
import ProgressBar from '../../components/progressBar';



// Initialize Firebase (replace with your config)
const firebaseConfig = {
  apiKey: "AIzaSyDz4KeNIcdmLwRuKLOes-7NN7wBkLJAMYQ",
  authDomain: "calucid-6d287.firebaseapp.com",
  projectId: "calucid-6d287",
  storageBucket: "calucid-6d287.appspot.com",
  messagingSenderId: "526773370648",
  appId: "1:526773370648:web:7ecf61233514bb74b1286d",
  measurementId: "G-JLHGWWC373"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);




function WebsiteDesignGrader() {
  const [isHomepageVisible, setHomepageVisible] = useState(true);
  const [isBlogpageVisible, setBlogpageVisible] = useState(true);
  const [isLoadingScreenVisible, setLoadingScreenVisible] = useState(false);
  const [isToolResultsVisible, setToolResultsVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [analysisResults, setAnalysisResults] = useState(null);

    // Effect to handle URL query parameters on component mount
useEffect(() => {
      // Get URL parameters
      const queryParams = new URLSearchParams(window.location.search);
      const urlParam = queryParams.get('url');
      
      if (urlParam) {
        // Decode the URL parameter in case it was encoded
        const decodedUrl = decodeURIComponent(urlParam);
        setInputValue(decodedUrl);
      }
    }, []); 

  const isValidInput = (input) => {
    const pattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,})([\/\w \.-]*)*\/?$/;
    return pattern.test(input);
  };

  const normalizeURL = (input) => {
    if (!/^https?:\/\//i.test(input)) {
      return `https://${input}`;
    }
    return input;
  };

  const startAnalysis = async () => {
    let url = inputValue.trim();
    
    if (!isValidInput(url)) {
      setErrorMessage('Please enter a valid domain or URL.');
      return;
    }

    url = normalizeURL(url);
    setErrorMessage('');
    setHomepageVisible(false);
    setBlogpageVisible(false);
    setLoadingScreenVisible(true);

    try {
      // Step 1: Get the user's IP address using an API
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const userIp = ipData.ip;
  
      // Step 2: Save the URL and IP address to Firestore
      await addDoc(collection(db, 'WebDesignTool'), {
        url: url,
        ip: userIp,
        timestamp: new Date(),
      });
  
      console.log('Data uploaded successfully:', { url, userIp });
      // You can proceed with other operations here, e.g., analysis or UI updates
  
    } catch (error) {
      setErrorMessage('An error occurred while fetching the IP address or saving the data.');
      console.error('Error:', error);
    } 

    try {
      const result = await fetch(`https://calucidserver-production.up.railway.app/analyzeWebsite?url=${encodeURIComponent(url)}`)
      .then(response => response.json()) // Convert the response to JSON
      .then(data => {
          console.log('Response from server:', data);  
          runAnalysis(data);
          setLoadingScreenVisible(false);
          setToolResultsVisible(true);
      })
      .catch(error => {
          console.error('Error:', error);
          resetToInitialConditions();
          alert(url+" does not exist. Make sure the url entered is a valid website.");
      });

    } catch (error) {
      console.error('Error analyzing website:', error);
      setErrorMessage('An error occurred while analyzing the website. Please try again.');
      console.error('Detailed error:', {
        code: error.code,
        message: error.message,
        details: error.details,
        stack: error.stack
      });
      resetToInitialConditions();
    }
  };


  function metaTags(html) {
    // Parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    // Select all meta tags
    const metaElements = doc.getElementsByTagName('meta');
    const metaInfo = {
      totalMetaTags: metaElements.length,
      general: [],
      social: {
        openGraph: [],
        twitter: []
      },
      seo: []
    };
    
    // Classify meta tags
    for (let meta of metaElements) {
      const metaAttributes = {};
      
      for (let attr of meta.attributes) {
        metaAttributes[attr.name] = attr.value;
      }
      
      // Check for social media tags
      if (meta.getAttribute('property') && meta.getAttribute('property').startsWith('og:')) {
        // Open Graph tags (e.g., og:title, og:image)
        metaInfo.social.openGraph.push(metaAttributes);
      } else if (meta.getAttribute('name') && meta.getAttribute('name').startsWith('twitter:')) {
        // Twitter tags (e.g., twitter:card, twitter:site)
        metaInfo.social.twitter.push(metaAttributes);
      }
      
      // Check for SEO tags
      else if (['description', 'keywords', 'author', 'robots'].includes(meta.getAttribute('name'))) {
        metaInfo.seo.push(metaAttributes);
      }
      
      // General meta tags (non-specific types)
      else {
        metaInfo.general.push(metaAttributes);
      }
    }
    
    return metaInfo;
  }



  function analyzeDesign(html, css) {
    // Parse HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Parse CSS
    const styleSheet = new CSSStyleSheet();
    styleSheet.replaceSync(css);

    // Helper functions
    const getAnimations = () => {
        const animations = [];
        for (const rule of styleSheet.cssRules) {
            if (rule instanceof CSSKeyframesRule) {
                animations.push(rule.name);
            } else if (rule instanceof CSSStyleRule && rule.style.animationName) {
                animations.push(rule.style.animationName);
            }
        }
        return [...new Set(animations)];  // Unique animation names
    };

    const getColorScheme = () => {
        const colors = new Set();
        for (const rule of styleSheet.cssRules) {
            if (rule instanceof CSSStyleRule) {
                Array.from(rule.style).forEach(prop => {
                    if (prop.includes('color') || prop === 'background') {
                        const colorValue = rule.style[prop];
                        colors.add(colorValue);
                    }
                });
            }
        }
        return Array.from(colors);
    };

    const getTextMetrics = () => {
        const textElements = doc.querySelectorAll('p, h1, h2, h3, h4, h5, h6, span');
        const textMetrics = [];
        textElements.forEach(el => {
            const style = window.getComputedStyle(el);
            textMetrics.push({
                fontSize: style.fontSize,
                lineHeight: style.lineHeight,
                fontWeight: style.fontWeight,
            });
        });
        return textMetrics;
    };

    const getBordersAndShadows = () => {
        const elements = doc.querySelectorAll('*');
        const borders = [];
        const shadows = [];
        elements.forEach(el => {
            const style = window.getComputedStyle(el);
            if (style.borderRadius && style.borderRadius !== '0px') {
                borders.push(style.borderRadius);
            }
            if (style.boxShadow && style.boxShadow !== 'none') {
                shadows.push(style.boxShadow);
            }
        });
        return { borders, shadows };
    };

    const getGradients = () => {
        const gradients = [];
        for (const rule of styleSheet.cssRules) {
            if (rule instanceof CSSStyleRule) {
                Array.from(rule.style).forEach(prop => {
                    const value = rule.style[prop];
                    if (value.includes('gradient')) {
                        gradients.push(value);
                    }
                });
            }
        }
        return gradients;
    };

    // Run analysis
    return {
        animations: getAnimations(),
        colorScheme: getColorScheme(),
        textMetrics: getTextMetrics(),
        bordersAndShadows: getBordersAndShadows(),
        gradients: getGradients(),
    };
}



function runAnalysis(rawData){

  var metaTags1 = metaTags(rawData.html);
  console.log(metaTags1);
  // var analysis1 = analyzeDesign(rawData.html, rawData.css[0]);
  // console.log(analysis1);
  const isHttps = (rawData.metrics.finalUrl).includes("https")
  var obj ={
    isHttps: isHttps,
    urlLength: (rawData.metrics.finalUrl).length,
    pageSpeed: rawData.metrics.loadTime/1000,
    pageSize: rawData.metrics.totalSize/1000000,
    pagebps: rawData.metrics.totalSize/rawData.metrics.loadTime/1000,
    totalMetaTags: metaTags1.totalMetaTags,
    generalMetaTags: metaTags1.general.length,
    seoMetaTags: metaTags1.seo.length,
    OGMetaTags: metaTags1.social.openGraph.length,
    twitterMetaTags: metaTags1.social.twitter.length,
    copyAnalysis: rawData.copyanalysis.analysis



  }

  console.log(obj);
  setAnalysisResults(obj);
  
}


  const resetToInitialConditions = () => {
    setHomepageVisible(true);
    setBlogpageVisible(true);
    setLoadingScreenVisible(false);
    setToolResultsVisible(false);
    setInputValue('');
    setErrorMessage('');
    setAnalysisResults(null);
  };


  const renderAnalysisResults = () => {
    if (!analysisResults) return null;

    console.log(analysisResults);

    return (
      <div className='toolresults'>
        <h1>Tool Results</h1>
        <h3>{inputValue}</h3>
        
        {/* Website Information */}
        
        <div>
          <h2>Speed and Security</h2>
          <p><strong>HTTPS Encrypted:</strong> {analysisResults.isHttps ? "Yes" : "No"}</p>
          <p><strong>URL Length:</strong> {analysisResults.urlLength} characters</p>
          <p><strong>Page Speed:</strong> {analysisResults.pageSpeed} Seconds</p>
          <p><strong>Page Size:</strong> {analysisResults.pageSize} mb</p>
          <p><strong>Bytes per Second:</strong> {analysisResults.pagebps} mb/s</p>
        </div>

        <div>
          <h2>Meta Tags</h2>
          <p><strong>Total Tags:</strong> {analysisResults.totalMetaTags}</p>
          <p><strong>General Tags:</strong> {analysisResults.generalMetaTags}</p>
          <p><strong>SEO Tags:</strong> {analysisResults.seoMetaTags}</p>
          <p><strong>Social Tags:</strong> {analysisResults.OGMetaTags}</p>
          <p><strong>Twitter Tags:</strong> {analysisResults.twitterMetaTags}</p>
        </div>

        <div>
          <h2>Website Copy</h2>
          <div dangerouslySetInnerHTML={{ __html: analysisResults.copyAnalysis }} />

        </div>

        {/* <div>
          <h2>Design Analysis</h2>
          <p><strong>Unique Design:</strong> {analysisResults.totalMetaTags}</p>
          <p><strong>Unique Design:</strong> {analysisResults.totalMetaTags}</p>

        </div> */}





        <button onClick={resetToInitialConditions}>Go Back</button>
      </div>
    );
  };





  return (

    <div>
        <Helmet>
          <title>Free Website Design Grader</title>
          <meta name="description" content="No login or email required. Use Calucid's free website design grader to audit, evaluate and analyzes your site’s content, design, and performance, providing actionable insights to improve user experience and boost engagement. Try it now!" />
          <meta name="keywords" content="Website design grader, Website design analysis, Calucid Website Design Grader, website audit, Calucid Tools, Free website checker, Website usability test, Site design audit, Website performance review, Improve website design, Website user experience grader, Web design SEO tool, Free website audit tool"/>
      </Helmet>
      {isHomepageVisible && (
        <div className='homepage'>
          <h1>Website Design Grader</h1>
          <h3>By Calucid</h3>
          <div className='inputBox'>
            <input 
              placeholder='Your Website URL or Domain' 
              value={inputValue} 
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button onClick={startAnalysis}>Start My Analysis</button>
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <h3>Get your answer in minutes.</h3>
          <br></br><br></br><br></br><br></br><br></br>
        </div>
      )}

      {isBlogpageVisible && (
        <div className='toolinfopage'>
          <h1>How it works</h1>
          <li>Step 1: Enter in Your URL</li>
          <li>Step 2: Press 'Start My Analysis'</li>
          <li>Step 3: Review Your Results</li>
          <h2>Enhance Your Website with Our Free Website Design Grader Tool</h2>
          <p>In the digital age, having an attractive and user-friendly website is crucial for your business's success. Our <strong>Free Website Design Grader Tool</strong> allows you to evaluate your website’s design quality effortlessly. By entering your URL, you’ll receive a comprehensive assessment of your website’s aesthetics, usability, and overall performance. This valuable feedback can help you create a more engaging user experience and increase conversions. Start optimizing your website today with our easy-to-use grading tool!</p>

          <h2>Why Use a Website Design Grader Tool?</h2>
          <p>Website design plays a vital role in establishing brand credibility and retaining visitors. Our <strong>website design grader</strong> evaluates essential elements like <strong>mobile responsiveness</strong>, <strong>loading speed</strong>, and <strong>visual appeal</strong>, providing you with actionable insights. A well-designed website not only attracts visitors but also keeps them engaged longer, improving your overall SEO and helping you climb search engine rankings. Take advantage of our free tool to ensure your website is working hard for your brand.</p>

          <h2>How Our Website Design Grader Tool Works</h2>
          <p>Getting started with our tool is simple. Enter your URL, and in moments, our sophisticated algorithms will analyze your website's design components. The <strong>website design grader</strong> assesses various factors, including <strong>navigation ease</strong>, <strong>color scheme</strong>, and <strong>content layout</strong>. With no cost involved, you can obtain a detailed report that highlights strengths and areas for improvement, empowering you to make data-driven decisions for your website redesign.</p>

          <h2>Get Your Free Website Design Grade and Boost Your Online Presence</h2>
          <p>Our <strong>free website design grader tool</strong> provides the insights you need to elevate your website to the next level. Whether you’re a business owner, a web developer, or a digital marketer, this tool will help you identify critical design issues that could be affecting your user engagement and conversion rates. Don’t let a poorly designed website hold you back—enter your URL now and discover how to enhance your online presence!</p>


          <br></br><br></br>
        </div>
      )}

      {isLoadingScreenVisible && (
        <div className='loadingscreen'>
          <h1>Loading...</h1>
          <h3>{inputValue}</h3>
          <ProgressBar></ProgressBar>
          <p>(this can take up to 2 minutes)</p>
        </div>
      )}

      {isToolResultsVisible && renderAnalysisResults()}
    </div>
  );
}

export default WebsiteDesignGrader;