import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Heading,
  Select,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { useNavigate } from "react-router-dom"; // For navigation after submission
import { initializeApp } from 'firebase/app';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDz4KeNIcdmLwRuKLOes-7NN7wBkLJAMYQ",
  authDomain: "calucid-6d287.firebaseapp.com",
  projectId: "calucid-6d287",
  storageBucket: "calucid-6d287.appspot.com",
  messagingSenderId: "526773370648",
  appId: "1:526773370648:web:7ecf61233514bb74b1286d",
  measurementId: "G-JLHGWWC373"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const WebsiteDesignForm = () => {
  const formBg = useColorModeValue("gray.800", "gray.900");
  const accentColor = useColorModeValue("blue.400", "teal.300");
  const accentColor2 = useColorModeValue("purple.700", "teal.300");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    businessDescription: "",
    existingWebsite: "",
    desiredUrl: "",
    colorScheme: "",
    styleTheme: "",
    socialMediaLinks: "",
    additionalInfo: "",
    targetAudience: "",
    requiredFeatures: "",
    competitorWebsites: "",
  });

  // Handle form changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

// Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  
  // Add timestamp to formData
  const timestamp = new Date(); // current timestamp
  const formDataWithTimestamp = { ...formData, timestamp };

  try {
    await addDoc(collection(db, "websiteDesignRequests"), formDataWithTimestamp);
    setLoading(false);
    window.location.href = "/confirmation";
  } catch (error) {
    console.error("Error submitting form:", error);
    setLoading(false);
  }
};

  return (
    <Box
      maxWidth="700px"
      mx="auto"
      mt={5}
      bg={formBg}
      p={2}
      borderRadius="md"
      boxShadow="lg"
      color="white"
    >
      <Heading mb={6} textAlign="center" color={accentColor}>
        Website Design Specifications
      </Heading>
      <Heading mb={3} textAlign="center" color={accentColor2}  size="md">
        Results Now, Pay Later
      </Heading>
      <Heading mb={6} textAlign="center" color={accentColor2}  size="sm">
      We build your website at no cost upfront – you only pay if you love it. Once you review your new website and are 100% satisfied, it’s just $49.99/month. No risk, only reward.      </Heading>
      <VStack spacing={4} as="form" onSubmit={handleSubmit}>
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input type="text" placeholder="Your Name" value={formData.name} onChange={handleChange} />
        </FormControl>

        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input type="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
        </FormControl>

        <FormControl id="phone" isRequired>
          <FormLabel>Phone Number</FormLabel>
          <Input type="tel" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} />
        </FormControl>

        <FormControl id="companyName" isRequired>
          <FormLabel>Company Name</FormLabel>
          <Input type="text" placeholder="Your Company Name" value={formData.companyName} onChange={handleChange} />
        </FormControl>

        <FormControl id="businessDescription" isRequired>
          <FormLabel>Business Description</FormLabel>
          <Textarea placeholder="Just a short one to three sentence decription of your business" value={formData.businessDescription} onChange={handleChange} />
        </FormControl>

        <FormControl id="businessAddress">
          <FormLabel>Business Address (if applicable)</FormLabel>
          <Textarea placeholder="The Address for your business" value={formData.targetAudience} onChange={handleChange} />
        </FormControl>

        <FormControl id="existingWebsite">
          <FormLabel>Existing Website (if applicable)</FormLabel>
          <Input type="url" placeholder="https://yourwebsite.com" value={formData.existingWebsite} onChange={handleChange} />
        </FormControl>

        <FormControl id="desiredUrl">
          <FormLabel>Desired URL (optional)</FormLabel>
          <Input type="url" placeholder="https://desiredwebsite.com" value={formData.desiredUrl} onChange={handleChange} />
        </FormControl>

        <FormControl id="colorScheme">
          <FormLabel>Color Scheme</FormLabel>
          <Input type="text" placeholder="Preferred colors" value={formData.colorScheme} onChange={handleChange} />
        </FormControl>

        <FormControl id="styleTheme">
          <FormLabel>Style/Theme (optional)</FormLabel>
          <Select style={{color:"grey"}} placeholder="Select a theme" value={formData.styleTheme} onChange={handleChange}>
            <option style={{color:"black"}} value="modern">Modern</option>
            <option style={{color:"black"}} value="minimalist">Minimalist</option>
            <option style={{color:"black"}} value="vintage">Vintage</option>
            <option style={{color:"black"}} value="corporate">Corporate</option>
            <option style={{color:"black"}} value="creative">Creative</option>
          </Select>
        </FormControl>

        <FormControl id="socialMediaLinks">
          <FormLabel>Social Media Links (if applicable)</FormLabel>
          <Textarea placeholder="Links to your social media profiles" value={formData.socialMediaLinks} onChange={handleChange} />
        </FormControl>



        {/* <FormControl id="requiredFeatures">
          <FormLabel>Required Features (optional)</FormLabel>
          <Textarea placeholder="E.g., contact forms, etc." value={formData.requiredFeatures} onChange={handleChange} />
        </FormControl> */}

        <FormControl id="competitorWebsites">
          <FormLabel>Competitor Websites (optional)</FormLabel>
          <Textarea placeholder="Links to competitor sites or inspiration" value={formData.competitorWebsites} onChange={handleChange} />
        </FormControl>

        <FormControl id="additionalInfo">
          <FormLabel>Additional Information (if applicable)</FormLabel>
          <Textarea placeholder="Any other information about the desired website" value={formData.additionalInfo} onChange={handleChange} />
        </FormControl>


        <HStack width="100%" justify="flex-end" pt={4}>
          <Button colorScheme="cyan" type="submit" isLoading={loading}>
          Start Your Journey
          </Button>
          {/* <Button colorScheme="purple" variant="outline" type="reset">
            Reset
          </Button> */}
        </HStack>
      </VStack>
    </Box>
  );
};

export default WebsiteDesignForm;
