import React, { useState, useEffect } from 'react';
import '../styles.css';

function ProgressBar() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const duration = 100000; // Total duration in milliseconds (2 minutes)
    const interval = 100; // Update interval in milliseconds

    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const normalizedTime = elapsedTime / duration; // Normalize time (0 to 1)
      const exponentialProgress = 100 * (1 - Math.exp(-5 * normalizedTime)); // Exponential growth function

      setProgress(Math.min(exponentialProgress, 100)); // Cap progress at 100

      if (elapsedTime >= duration) {
        clearInterval(timer); // Stop the timer when complete
      }
    }, interval);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, []);

  return (
    <div className='progressBarContainer'>
      <div
        className='progressBar'
        style={{
          width: `${progress}%`,
          transition: 'width 0.1s linear',
        }}
      ></div>
    </div>
  );
}

export default ProgressBar;
